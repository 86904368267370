import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

const ProcessForm = ({
  process: initialProcess,
  id,
  handleSubmit,
  handleCancel,
}) => {
  const [loading, setLoading] = useState(true);
  const [process, setProcess] = useState(initialProcess);

  useEffect(() => {
    if (!initialProcess.faq) initialProcess.faq = [];
    setProcess(initialProcess);
    setLoading(false);
  }, [initialProcess]);

  const handleAddFaq = () => {
    setProcess({
      ...process,
      faq: [...process.faq, { question: "", answer: "" }],
    });
  };

  const handleFaqChange = (index, field, value) => {
    const newFaq = [...process.faq];
    newFaq[index][field] = value;
    setProcess({ ...process, faq: newFaq });
  };

  const handleRemoveFaq = (index) => {
    const newFaq = process.faq.filter((_, i) => i !== index);
    setProcess({ ...process, faq: newFaq });
  };

  if (loading) return;

  return (
    <>
      <form onSubmit={(e) => handleSubmit(e, process)}>
        <div className="form-group">
          <label>Name</label>
          <input
            type="text"
            value={process.name}
            onChange={(e) => setProcess({ ...process, name: e.target.value })}
            required
          />
        </div>

        <div className="form-group">
          <label>Description</label>
          <textarea
            value={process.description}
            onChange={(e) =>
              setProcess({ ...process, description: e.target.value })
            }
            rows="5"
            required
          />
        </div>

        <div className="form-group">
          <label>Duration</label>
          <input
            type="text"
            value={process.duration}
            onChange={(e) =>
              setProcess({ ...process, duration: e.target.value })
            }
          />
        </div>

        <div className="form-group">
          <h2>FAQ</h2>
          {process.faq.map((faq, index) => (
            <div key={index} className="faq-item">
              <input
                type="text"
                placeholder="Question"
                value={faq.question}
                onChange={(e) =>
                  handleFaqChange(index, "question", e.target.value)
                }
                required
              />
              <textarea
                placeholder="Answer"
                value={faq.answer}
                onChange={(e) =>
                  handleFaqChange(index, "answer", e.target.value)
                }
                rows="3"
              />
              <button type="button" onClick={() => handleRemoveFaq(index)}>
                <FontAwesomeIcon icon={faTrashAlt} />
              </button>
            </div>
          ))}
          <button type="button" onClick={handleAddFaq}>
            <FontAwesomeIcon icon={faPlus} />
          </button>
        </div>

        <div className="form-group">
          <button type="submit" className="btn-submit">
            {id ? "Save" : "Add Process"}
          </button>
          <button
            type="button"
            onClick={() => handleCancel()}
            className="btn-cancel"
          >
            Cancel
          </button>
        </div>
      </form>
      <br />
    </>
  );
};

export default ProcessForm;
