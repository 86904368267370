import React, { useState, useEffect, useContext } from 'react';

import { CompanyContext } from '../../contexts/CompanyContext';

const CompanyForm = ({ company, onSubmit, onCancel }) => {
    const {countries} = useContext(CompanyContext);
    const [id,setId] = useState(null);
    const [localCompany, setLocalCompany] = useState(company);

    useEffect(() => {
        if (company.id) {
            setId(company.id);
        }
    }, [company]);

    const handleNameChange = (e) => {
        const name = e.target.value;
        setLocalCompany({
            ...localCompany,
            name,
            slug: generateSlug(name)
        });
    };

    const generateSlug = (name) => {
        return name.toLowerCase().replace(/ /g, '-');
    };

    const handleCancel = (e) => {
        e.preventDefault();
        onCancel();
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(localCompany);
    };

    return (
        <div className="form-container">
            <h2>{id ? 'Edit Company' : 'New Company'}</h2>
            <form onSubmit={handleSubmit} className="company-form">
                <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input
                        type="text"
                        id="name"
                        value={localCompany.name}
                        onChange={handleNameChange}
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="country">Country</label>
                    <select
                        id="country"
                        value={localCompany.country}
                        onChange={(e) => setLocalCompany({ ...localCompany, country: e.target.value })}
                        required
                    >
                        <option value="">Select a country</option>
                        {countries.map((country) => (
                            <option key={country.code} value={country.code}>
                                {country.name}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="form-group">
                    <label htmlFor="slug">Slug (auto-generado)</label>
                    <input
                        type="text"
                        id="slug"
                        value={localCompany.slug}
                        readOnly
                    />
                </div>

                <div className="form-group">
                    <button type="submit" className="btn-submit">{id ? 'Save' : 'Add company'}</button>
                    <button type="button" className="btn-cancel" onClick={handleCancel}>Cancel</button>
                </div>
            </form>
        </div>
    );
};

export default CompanyForm;