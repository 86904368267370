// src/services/ExamServices.js
import axiosInstance from "./axiosConfig";

const API_URL = process.env.REACT_APP_API_URL;

export const getExams = async (companyId) => {
  try {
    const response = await axiosInstance.get(`${API_URL}/exam-company/${companyId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching exams:", error);
    throw error;
  }
};

// Obtener un examen por su ID
export const getExamById = async (id) => {
  try {
    const response = await axiosInstance.get(`${API_URL}/exam/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching exam:", error);
    throw error;
  }
};

// Crear un nuevo examen
export const createExam = async (exam) => {
  try {
    const e = {
      name: exam.name,
      description: exam.description,
      length: exam.length,
      preparation: exam.preparation,
      scope: exam.scope,
      remarks: exam.remarks,
      companyId: exam.companyId,
      parentId: exam.parentId,
      faq: exam.faq,
      files: exam.files,
    };
    const response = await axiosInstance.put(`${API_URL}/exam`, e);
    return response.data;
  } catch (error) {
    console.error("Error creating exam:", error);
    throw error;
  }
};

// Actualizar un examen existente
export const updateExam = async (id, exam) => {
  try {
    const e = {
      id: id,
      name: exam.name,
      description: exam.description,
      length: exam.length,
      preparation: exam.preparation,
      scope: exam.scope,
      remarks: exam.remarks,
      companyId: exam.companyId,
      parentId: exam.parentId,
      faq: exam.faq,
      files: exam.files,
    };
    const response = await axiosInstance.put(`${API_URL}/exam`, e);
    return response.data;
  } catch (error) {
    console.error("Error updating exam:", error);
    throw error;
  }
};

// Eliminar un examen
export const deleteExam = async (id) => {
  try {
    const response = await axiosInstance.delete(`${API_URL}/exam/${id}`);
    return response.data;
  } catch (error) {
    return { success: false, message: "Error deleting the exam", error };
  }
};
