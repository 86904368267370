// src/services/ExamServices.js
import axiosInstance from "./axiosConfig";

const API_URL = process.env.REACT_APP_API_URL;

export const closeConversation = async (conversationId) => {
  try {
    const response = await axiosInstance.post(`${API_URL}/conversation/close/${conversationId}`,{});
    return response.data;
  } catch (error) {
    console.error("Error fetching conversation:", error);
    throw error;
  }
}

export const getConversation = async (companyId) => {
  try {
    const response = await axiosInstance.get(`${API_URL}/conversation/${companyId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching conversation:", error);
    throw error;
  }
};

export const sendMessage = async (companyId,conversationId,message) => {
  try {
    const response = await axiosInstance.post(`${API_URL}/question`, {
      text: message,
      companyId: companyId,
      conversationId: conversationId,
    });
    return response.data;
  } catch (error) {
    console.error("Error sending message:", error);
    throw error;
  }
}