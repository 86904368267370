import React, { useState, useEffect, useCallback } from "react";
import {
    faArrowsAlt,
    faEdit,
    faPlus,
    faTrashAlt,
  } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { TreeViewComponent } from "@syncfusion/ej2-react-navigations";

import {
  createExam,
  deleteExam,
  updateExam,
} from "../../services/ExamServices";
import { deleteFile, uploadFile } from "../../services/FileServices";
import ExamForm from "./ExamForm";
import ExamMoveForm from "./ExamMoveForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ExamTreeView = ({ company }) => {
  const [base64File, setBase64File] = useState("");
  const [data, setData] = useState([]);
  const [exam, setExam] = useState(null);
  const [fileExtension, setFileExtension] = useState("");
  const [loading, setLoading] = useState(true);
  const [mode, setMode] = useState("view");
  const [selectedNode, setSelectedNode] = useState(null);
  const [updateCounter, setUpdateCounter] = useState(0);

  const getSubexams = useCallback((exam) => {
    let subExams = [];
    if (exam.exams) {
      exam.exams.forEach((subexam) => {
        const maxChars = Math.floor(window.innerWidth / (12*2)) - 3;
        let examName =
          subexam.name.length > maxChars
            ? subexam.name.substring(0, maxChars - 3) + "..."
            : subexam.name;
        subExams.push({
          id: subexam.id,
          name: examName,
          type: "exam",
          subChild: getSubexams(subexam),
          expanded: true,
        });
      });
    }
    return subExams;
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      let childExams = [];
      company.exams.forEach((exam) => {
        let examName = exam.name;
        const maxChars = Math.floor(window.innerWidth / (12*2)) - 3;
        if (examName.length > maxChars) {
          examName = examName.substring(0, maxChars - 3) + "...";
        }
        childExams.push({
          id: exam.id,
          name: examName,
          subChild: getSubexams(exam),
          expanded: true,
        });
      });
      let treeData = [
        {
          id: company.id,
          name: "Exams",
          subChild: childExams,
          expanded: true,
        },
      ];
      const fields = {
        dataSource: treeData,
        id: "id",
        text: "name",
        child: "subChild",
      };
      setData(fields);
      setLoading(false);
    };
    fetchData();
  }, [company, updateCounter, getSubexams]);

  const findExamById = (exams, id) => {
    for (let exam of exams) {
      if (exam.id === id) {
        return exam;
      }
      if (exam.exams) {
        const found = findExamById(exam.exams, id);
        if (found) {
          return found;
        }
      }
    }
    return null;
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setBase64File(reader.result);
        setFileExtension(file.name.split(".").pop());
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFileDelete = async (index) => {
    await deleteFile("exam", exam.id, index);
    const files = [...exam.files];
    files.splice(index, 1);
    setExam({ ...exam, files: files });
  };

  const handleFileUpload = async (event) => {
    event.preventDefault();
    const data = {
      id: exam.id,
      fileContent: base64File,
      fileExtension: fileExtension,
    };
    const r=await uploadFile("exam", data);
    const files = [...exam.files,r];
    setExam({ ...exam, files: files });
  };

  const onDelete = async () => {
    if (selectedNode && selectedNode.id !== company.id) {
      if (selectedNode.hasChildren) return;
      setLoading(true);
      const examId = selectedNode.id;
      await deleteExam(examId);
      const removeExamById = (exams, id) => {
        return exams.filter((exam) => {
          if (exam.id === id) {
            return false;
          }
          if (exam.exams) {
            exam.exams = removeExamById(exam.exams, id);
          }
          return true;
        });
      };

      company.exams = removeExamById(company.exams, examId);
      setExam(null);
      setSelectedNode(null);
      setUpdateCounter(updateCounter + 1);
    }
  };

  const onEdit = () => {
    if (selectedNode && selectedNode.id !== company.id) {
      setMode("edit");
    }
  };

  const onMove = () => {
    if (selectedNode && selectedNode.id !== company.id) {
      setMode("move");
    }
  };

  const onNodeSelected = (args) => {
    const sn = args.nodeData;
    setSelectedNode(sn);

    const examId = sn.id;
    const currentExam = findExamById(company.exams, examId);
    if (currentExam) {
      setExam(currentExam);
    }
  };

  const onNodeAdd = async (parentId) => {
    const examId = parentId;
    setExam({
      name: "",
      description: "",
      length: "",
      preparation: "",
      scope: "",
      remarks: "",
      faq: [],
      companyId: company.id,
      parentId: examId,
    });
    setMode("edit");
  };

  const onSaveExam = async (e, newExam) => {
    e.preventDefault();
    setLoading(true);
    if (newExam.parentId === company.id) newExam.parentId = null;
    if (!newExam.id) {
      const ex = await createExam(newExam);
      ex.exams = [];
      if (newExam.parentId) {
        const parentExam = findExamById(company.exams, newExam.parentId);
        if (parentExam) {
          parentExam.exams = parentExam.exams || [];
          parentExam.exams.push(ex);
        }
      } else {
        company.exams.push(ex);
      }
    } else {
      await updateExam(newExam.id, newExam);
      const updateExamById = (exams, updatedExam) => {
        return exams.map((exam) => {
          if (exam.id === updatedExam.id) {
            return { ...exam, ...updatedExam };
          }
          if (exam.exams) {
            exam.exams = updateExamById(exam.exams, updatedExam);
          }
          return exam;
        });
      };
      setExam(newExam);

      company.exams = updateExamById(company.exams, newExam);
    }
    setExam(null);
    setSelectedNode(null);
    setMode("view");
    setUpdateCounter(updateCounter + 1);
  };

  return (
    <div className="process-view">
      <div className="process-tree">
        <div
          style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}
        >
          <button
            onClick={() => onNodeAdd(selectedNode ? selectedNode.id : null)}
            disabled={!selectedNode}
          >
            <FontAwesomeIcon icon={faPlus} />
          </button>
          <button
            onClick={onEdit}
            disabled={!selectedNode || selectedNode.id === company.id}
          >
            <FontAwesomeIcon icon={faEdit} />
          </button>
          <button
            onClick={onDelete}
            disabled={!selectedNode || selectedNode.hasChildren}
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </button>
          <button
            onClick={onMove}
            disabled={!selectedNode || selectedNode.id === company.id}
          >
            <FontAwesomeIcon icon={faArrowsAlt} />
          </button>
        </div>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <TreeViewComponent fields={data} nodeSelected={onNodeSelected} />
        )}
      </div>
      <div className="process-details">
        {mode === "view" && selectedNode && selectedNode.id !== company.id && (
          <>
            <h3>Exam Details</h3>
            <p>
              <strong>Name:</strong> {exam.name}
            </p>
            <h4>Description</h4>
            <div
              dangerouslySetInnerHTML={{ __html: exam?.description || "" }}
            />
            <p>
              <strong>Length:</strong> {exam.length}
            </p>
            <p>
              <strong>Preparation:</strong> {exam.preparation}
            </p>
            <p>
              <strong>Scope:</strong> {exam.scope}
            </p>
            <p>
              <strong>Remarks:</strong> {exam.remarks}
            </p>
            <h2>FAQ</h2>
            {exam.faq &&
              exam.faq.map((faq, index) => (
                <div key={index} className="faq-item">
                  <p>
                    <strong>Question:</strong> {faq.question}
                  </p>
                  <p>
                    <strong>Answer:</strong> {faq.answer}
                  </p>
                </div>
              ))}
            <h2>Attachments</h2>
            {exam.files &&
              exam.files.map((file, index) => (
                <div key={index} className="file-item">
                  <Link
                    to="#"
                    onClick={() => window.open(file, "_blank")}
                  >{`File #${index + 1}`}</Link>
                  &nbsp;&nbsp;
                  <button onClick={() => handleFileDelete(index)}>
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </button>
                </div>
              ))}
            <div className="upload-container">
              <h3>Load file</h3>
              <form onSubmit={handleFileUpload}>
                <div className="form-group">
                  <input type="file" onChange={handleFileChange} required />
                </div>
                <div className="form-group">
                  <button type="submit" className="btn-submit">
                    Upload
                  </button>
                </div>
              </form>
            </div>
          </>
        )}
        {mode === "edit" && (
          <ExamForm
            exam={exam}
            id={exam.id}
            handleCancel={() => {
              setMode("view");
            }}
            handleSubmit={onSaveExam}
          />
        )}
        {mode === "move" && (
          <ExamMoveForm
            exam={exam}
            company={company}
            id={exam.id}
            handleCancel={() => {
              setMode("view");
            }}
            handleSubmit={onSaveExam}
          />
        )}
      </div>
    </div>
  );
};

export default ExamTreeView;
