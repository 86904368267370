// src/services/processService.js
import axiosInstance from "./axiosConfig";

const API_URL = process.env.REACT_APP_API_URL;

export const getProcesses = async (companyId) => {
  try {
    const response = await axiosInstance.get(`${API_URL}/process-company/${companyId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching processes:", error);
    throw error;
  }
};

// Obtener un proceso por su ID
export const getProcessById = async (id) => {
  try {
    const response = await axiosInstance.get(`${API_URL}/process/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching process:", error);
    throw error;
  }
};

// Crear un nuevo proceso
export const createProcess = async (process) => {
  try {
    const p = {
      companyId: process.companyId,
      name: process.name,
      description: process.description,
      duration: process.duration,
      faq: process.faq,
      parentId: process.parentId
    }
    const response = await axiosInstance.put(`${API_URL}/process`, p);
    return response.data.attributes;
  } catch (error) {
    console.error("Error creating process:", error);
    throw error;
  }
};

// Actualizar un proceso existente
export const updateProcess = async (id, process) => {
  try {
    const p = {
      id: id,
      companyId: process.companyId,
      name: process.name,
      description: process.description,
      duration: process.duration,
      faq: process.faq,
      parentId: process.parentId
    }
    const response = await axiosInstance.put(`${API_URL}/process`, p);
    return response.data;
  } catch (error) {
    console.error("Error updating process:", error);
    throw error;
  }
};

// Eliminar un proceso
export const deleteProcess = async (id) => {
  try {
    const response = await axiosInstance.delete(`${API_URL}/process/${id}`);
    return response.data;
  } catch (error) {
    return { success: false, message: "Error deleting the process", error };
  }
};
