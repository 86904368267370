import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

import ExamTreeView from './ExamTreeView';
import ProcessTreeView from './ProcessTreeView';

import './CompanyView.css';

const CompanyView = ({ company, onEdit }) => {
    return (
        <div className="company-view">
            <div className="company-card">
                <button onClick={onEdit} className="btn-edit"><FontAwesomeIcon icon={faEdit} /></button>
                <div className="company-details">
                    <h2>{company.name}</h2>
                    <p><strong>Country:</strong> {company.countryName}</p>
                    <p><strong>Slug:</strong> {company.slug}</p>
                </div>
            </div>
            <div className="company-card">
                <h3>Process Tree</h3>
                <ProcessTreeView company={company} />
            </div>
            <div className="company-card">
                <h3>Exam Tree</h3>
                <ExamTreeView company={company} />
            </div>
        </div>
    );
};

export default CompanyView;