import React, { useState, useEffect, useRef } from "react";
import * as AssistantServices from "../services/AssistantServices";
import { getCompanies } from "../services/CompanyServices";
import "./Assistant.css";

const Assistant = () => {
  const [company, setCompany] = useState(null);
  const [conversation, setConversation] = useState({ history: [] });
  const conversationEndRef = useRef(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [newMessage, setNewMessage] = useState("");
  const [updateCounter, setUpdateCounter] = useState(0);

  useEffect(() => {
    const fetchConversation = async () => {
      try {
        const list = await getCompanies();
        setCompany(list[0] ?? null);

        const response = await AssistantServices.getConversation(list[0]?.id ?? "1");
        if (!response.history) {
          response.history = [{ assistant: "How can I help you?", user: "" }];
        }
        setConversation(response);

      } catch (error) {
        setError("Error loading conversation " + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchConversation();
  }, []);

    useEffect(() => {
        setTimeout(scrollToBottom, 0);
    }, [updateCounter]);

  const handleSendMessage = async () => {
    if (newMessage.trim() === "") return;

    try {
      setLoading(true);
      const newItem = await AssistantServices.sendMessage(
        company?.id ?? "",
        conversation.id,
        newMessage
      );
      const history = [
        ...conversation.history,
        { user: newItem.question, assistant: newItem.answer },
      ];
      const updatedConversation = { ...conversation, history: history };
      setConversation(updatedConversation);
      setNewMessage("");
      setUpdateCounter(updateCounter + 1);
    } catch (error) {
      setError("Error sending message " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleClear = async () => {
    try {
      setLoading(true);
      await AssistantServices.closeConversation(
        conversation.id
      );
      const newConversation = {
        history: [{ assistant: "How can I help you?", user: "" }],
      };
      setConversation(newConversation);
      setNewMessage("");
      setUpdateCounter(updateCounter + 1);
    } catch (error) {
      setError("Error clearing conversation " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const scrollToBottom = () => {
    conversationEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [conversation.history]);

  if (loading) {
  }

  return (
    <div className={`assistant-container ${loading ? 'loading-cursor' : ''}`} >
      <div className="conversation-history">
        {conversation.history.map((entry, index) => (
          <div key={index}>
            {entry.user && (
              <>
                <div className="message-user">
                  <span>{entry.user}</span>
                </div>
                <br />
              </>
            )}
            {entry.assistant && (
              <>
                <div className="message-assistant">
                  <span>{entry.assistant}</span>
                </div>
                <br />
              </>
            )}
          </div>
        ))}
        <div ref={conversationEndRef} />
      </div>
      <div className="message-input">
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Type your message..."
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSendMessage();
            }
          }}
        />
        <button onClick={handleSendMessage}>Send</button>
        <button onClick={handleClear}>Clear</button>
      </div>
      {error && <div className="error-message">{error}</div>}
    </div>
  );
};

export default Assistant;
