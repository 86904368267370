// src/pages/CompanyForm.js
import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';

import { CompanyContext } from '../../contexts/CompanyContext';
import CompanyForm from './CompanyForm';
import CompanyView from './CompanyView';
import { createCompany, getCompanyById, updateCompany } from '../../services/CompanyServices';

import '../Forms.css';

function CompanyEdit() {
  const { id } = useParams();
  const [company, setCompany] = useState({ name: '', country: '', slug: '', processes: [], exams: [] });
  const { countries } = useContext(CompanyContext);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [mode, setMode] = useState('view');

  useEffect(() => {
    // Fetch company data based on the id
    const fetchCompany = async () => {
      setError(null);
      setLoading(true);
      try {
        if (id) {
          const companyData = await getCompanyById(id);
          if (companyData.country) {
            const companyCountry = countries.find(c => c.code === companyData.country);
            if (companyCountry)
              companyData.countryName = companyCountry.name;
          }
          setCompany(companyData);
        }
      } catch (error) {
        setError('Failed to fetch company data');
      } finally {
        setLoading(false);
      }
    };

    fetchCompany();
  }, [id,countries]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    const r = await saveCompany(company);
    if (r.success === false) {
      setError(r.message);
    }
  };

  const saveCompany = async (company) => {
    try {
      setLoading(true);
      if (company.id) {
        await updateCompany(company.id, company);
      } else {
        await createCompany(company);
      }
      return {
        success: true
      }
    } catch (error) {
      setLoading(false);
      console.error('Error submitting company:', error);
      return {
        success: false,
        message: error.message
      }
    } finally {
      setLoading(false);
    }
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="page-container">
      {error && (
        <div>
          <div className='error-message'>
            There was an error: {error}
          </div>
          <br />
        </div>
      )}
      {mode==='view' && (
        <CompanyView company={company} onEdit={() => setMode('edit')} />
      )}
      {mode==='edit' && (
        <CompanyForm company={company} onSubmit={handleSubmit} onCancel={() => setMode('view')} />
      )}
    </div>
  );
}

export default CompanyEdit;
