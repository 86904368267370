import React, { useState, useEffect } from "react";

const ProcessMoveForm = ({
  process: initialProcess,
  company,
  handleSubmit,
  handleCancel,
}) => {
  const [loading, setLoading] = useState(true);
  const [process, setProcess] = useState(initialProcess);
  const [parentId, setParentId] = useState(null);

  useEffect(() => {
    if (!initialProcess.faq) initialProcess.faq = [];
    setProcess(initialProcess);
    setLoading(false);
  }, [initialProcess]);

  const renderProcessOptions = (node, prefix = "") => {    
    if (node.id === process.id) return;
    
    if (node.processes && node.processes.length > 0) {
      return [
      <option key={node.id} value={node.id}>
        {prefix + node.name}
      </option>,
      ...node.processes.flatMap((childProcess) =>
        renderProcessOptions(childProcess, prefix + "--")
      ),
      ];
    } else {
      return (
      <option key={node.id} value={node.id}>
        {prefix + node.name}
      </option>
      );
    }
  }

  if (loading) return;

  return (
    <form onSubmit={(e) => handleSubmit(e, { ...process, parentId })}>
      <div className="form-group">
        <label>Move to</label>
        <select
          value={parentId}
          onChange={(e) => setParentId(e.target.value)}
          required
        >
          {renderProcessOptions({ id: company.id, name: "Processes", processes: company.processes})}
        </select>
      </div>

      <div className="form-group">
        <button type="submit" className="btn-submit">Save</button>
        <button
          type="button"
          onClick={() => handleCancel()}
          className="btn-cancel"
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default ProcessMoveForm;
