import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

const ExamForm = ({
  exam: initialExam,
  id,
  handleSubmit,
  handleCancel
}) => {
  const [exam, setExam] = useState(initialExam);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!initialExam.faq) initialExam.faq = [];
    setExam(initialExam);
    setLoading(false);
  }, [initialExam]);

  const handleAddFaq = () => {
    setExam({ ...exam, faq: [...exam.faq, { question: "", answer: "" }] });
  };

  const handleFaqChange = (index, field, value) => {
    const newFaq = [...exam.faq];
    newFaq[index][field] = value;
    setExam({ ...exam, faq: newFaq });
  };

  const handleRemoveFaq = (index) => {
    const newFaq = exam.faq.filter((_, i) => i !== index);
    setExam({ ...exam, faq: newFaq });
  };

  if (loading) return;

  return (
    <form onSubmit={(e) => handleSubmit(e, exam)}>
      <div className="form-group">
        <label>Name</label>
        <input
          type="text"
          value={exam.name}
          onChange={(e) => setExam({ ...exam, name: e.target.value })}
          required
        />
      </div>

      <div className="form-group">
        <label>Description</label>
        <textarea
          value={exam.description}
          onChange={(e) => setExam({ ...exam, description: e.target.value })}
          rows="5"
          required
        />
      </div>

      <div className="form-group">
        <label>Length</label>
        <input
          type="text"
          value={exam.length}
          onChange={(e) => setExam({ ...exam, length: e.target.value })}
        />
      </div>

      <div className="form-group">
        <label>Preparation</label>
        <textarea
          value={exam.preparation}
          onChange={(e) => setExam({ ...exam, preparation: e.target.value })}
          rows="5"
        />
      </div>

      <div className="form-group">
        <label>Scope</label>
        <textarea
          value={exam.scope}
          onChange={(e) => setExam({ ...exam, scope: e.target.value })}
          rows="5"
        />
      </div>

      <div className="form-group">
        <label>Remarks</label>
        <textarea
          value={exam.remarks}
          onChange={(e) => setExam({ ...exam, remarks: e.target.value })}
          rows="5"
        />
      </div>

      <div className="form-group">
        <h3>FAQ</h3>
        {exam.faq.map((faq, index) => (
          <div key={index} className="faq-item">
            <input
              type="text"
              placeholder="Question"
              value={faq.question}
              onChange={(e) =>
                handleFaqChange(index, "question", e.target.value)
              }
              required
            />
            <textarea
              placeholder="Answer"
              value={faq.answer}
              onChange={(e) => handleFaqChange(index, "answer", e.target.value)}
              rows="3"
              required
            />
            <button type="button" onClick={() => handleRemoveFaq(index)}>
              <FontAwesomeIcon icon={faTrashAlt} />
            </button>
          </div>
        ))}
        <button type="button" onClick={handleAddFaq}>
          <FontAwesomeIcon icon={faPlus} />
        </button>
      </div>

      <div className="form-group">
        <button type="submit" className="btn-submit">
          {id ? "Save" : "Add Exam"}
        </button>
        <button
          type="button"
          onClick={() => handleCancel()}
          className="btn-cancel"
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default ExamForm;
