import React, { createContext, useEffect, useState } from 'react';

import { createCompany, getCompanyById, updateCompany } from '../services/CompanyServices';
import { getCountries } from '../services/CoreServices';

export const CompanyContext = createContext();

export const CompanyProvider = ({ id, children }) => {
  const [company, setCompany] = useState({ name: '', country: '', slug: '', processes: [], exams: [] });
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCompany = async () => {
      try {
        const countryList = await getCountries();
        setCountries(countryList);

        const companyData = await getCompanyById(id);
        if (!companyData.country) {
          console.error('No hay país');
        }
        const companyCountry = countryList.find(c => c.code === companyData.country);
        if (companyCountry)
          companyData.countryName = companyCountry.name;
        setCompany(companyData);

      } catch (error) {
        console.error('Error fetching company:', error);
      } finally {
        setLoading(false);
      }
    }

    fetchCompany();
  }, [id]);

  const saveCompany = async (company) => {
    try {
      setLoading(true);
      if (company.id) {
        await updateCompany(company.id, company);
      } else {
        await createCompany(company);
      }
      return {
        success: true
      }
    } catch (error) {
      setLoading(false);
      console.error('Error submitting company:', error);
      return {
        success: false,
        message: error.message
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <CompanyContext.Provider value={{ 
      company,
      countries,
      loading,
      saveCompany,
      setCompany,
      setLoading
    }}>
      {children}
    </CompanyContext.Provider>
  );
};
