import React, { useState, useEffect } from "react";

const ExamMoveForm = ({
  exam: initialExam,
  company,
  handleSubmit,
  handleCancel,
}) => {
  const [loading, setLoading] = useState(true);
  const [exam, setExam] = useState(initialExam);
  const [parentId, setParentId] = useState(null);

  useEffect(() => {
    if (!initialExam.faq) initialExam.faq = [];
    setExam(initialExam);
    setLoading(false);
  }, [initialExam]);

  const renderExamOptions = (node, prefix = "") => {    
    if (node.id === exam.id) return;
    
    if (node.exams && node.exams.length > 0) {
      return [
      <option key={node.id} value={node.id}>
        {prefix + node.name}
      </option>,
      ...node.exams.flatMap((childExam) =>
        renderExamOptions(childExam, prefix + "--")
      ),
      ];
    } else {
      return (
      <option key={node.id} value={node.id}>
        {prefix + node.name}
      </option>
      );
    }
  }

  if (loading) return;

  return (
    <form onSubmit={(e) => handleSubmit(e, { ...exam, parentId })}>
      <div className="form-group">
        <label>Move to</label>
        <select
          value={parentId}
          onChange={(e) => setParentId(e.target.value)}
          required
        >
          {renderExamOptions({ id: company.id, name: "Exams", exams: company.exams})}
        </select>
      </div>

      <div className="form-group">
        <button type="submit" className="btn-submit">Save</button>
        <button
          type="button"
          onClick={() => handleCancel()}
          className="btn-cancel"
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default ExamMoveForm;
