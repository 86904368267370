// src/pages/Companies.js
import { faEye, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { deleteCompany, getCompanies } from '../../services/CompanyServices';

import styles from '../Table.module.css';

function Companies() {
  const [companies, setCompanies] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    fetchCompanies();
  }, []);

  const fetchCompanies = async () => {
    try {
      setError(null);
      const list = await getCompanies();
      setCompanies(list);
    } catch (error) {
      setError(error.message);
    }
    setLoading(false);
  };

  const handleDelete = async (id) => {
    setError(null);
    const r = await deleteCompany(id);
    if (r.success === false) {
      setError(r.message);
    } else {
      fetchCompanies();  
    }
  };

  if (loading) {
    return <div>Loading...</div>; 
  }

  return (
    <div>
      <h2>Companies</h2>
      {error && (
        <div>
          <div className='error-message'>
            There was an error: {error}
          </div>
          <br/>
        </div>
      )}
      <button onClick={() => navigate('/companies/new')}>
        <FontAwesomeIcon icon={faPlus} /> {/* Ícono de "+" */}
      </button>
      <br/>
      <br/>
      <table className={styles.table}>
        <thead>
          <tr>
            <th style={{ width: '70%' }}>Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {companies.map((company) => (
            <tr key={company.id}>
              <td>{company.name}</td>
              <td>
                <button onClick={() => navigate(`/companies/edit/${company.id}`)}>
                  <FontAwesomeIcon icon={faEye} />
                </button>
                <button onClick={() => handleDelete(company.id)}>
                  <FontAwesomeIcon icon={faTrashAlt} />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Companies;
