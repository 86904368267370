import React, { useState, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsAlt,
  faEdit,
  faPlus,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { TreeViewComponent } from "@syncfusion/ej2-react-navigations";

import {
  createProcess,
  deleteProcess,
  updateProcess,
} from "../../services/ProcessServices";
import { deleteFile, uploadFile } from "../../services/FileServices";
import ProcessForm from "./ProcessForm";
import ProcessMoveForm from "./ProcessMoveForm";

const ProcessTreeView = ({ company }) => {
  const [base64File, setBase64File] = useState("");
  const [data, setData] = useState([]);
  const [fileExtension, setFileExtension] = useState("");
  const [loading, setLoading] = useState(true);
  const [mode, setMode] = useState("view");
  const [process, setProcess] = useState(null);
  const [selectedNode, setSelectedNode] = useState(null);
  const [updateCounter, setUpdateCounter] = useState(0);

  const getSubprocesses = useCallback((process) => {
    let subProcesses = [];
    if (process.processes) {
      process.processes.forEach((subprocess) => {
        const maxChars = Math.floor(window.innerWidth / (12*2)) - 3;
        let processName =
          subprocess.name.length > maxChars
            ? subprocess.name.substring(0, maxChars - 3) + "..."
            : subprocess.name;
        subProcesses.push({
          id: subprocess.id,
          name: processName,
          type: "process",
          subChild: getSubprocesses(subprocess),
          expanded: true,
        });
      });
    }
    return subProcesses;
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      let childProcesses = [];
      company.processes.forEach((process) => {
        let processName = process.name;
        const maxChars = Math.floor(window.innerWidth / (12*2)) - 3;
        if (processName.length > maxChars) {
          processName = processName.substring(0, maxChars - 3) + "...";
        }
        childProcesses.push({
          id: process.id,
          name: processName,
          subChild: getSubprocesses(process),
          expanded: true,
        });
      });
      let treeData = [
        {
          id: company.id,
          name: "Processes",
          subChild: childProcesses,
          expanded: true,
        },
      ];
      const fields = {
        dataSource: treeData,
        id: "id",
        text: "name",
        child: "subChild",
      };
      setData(fields);
      setLoading(false);
    };
    fetchData();
  }, [company, updateCounter, getSubprocesses]);

  const findProcessById = (processes, id) => {
    for (let process of processes) {
      if (process.id === id) {
        return process;
      }
      if (process.processes) {
        const found = findProcessById(process.processes, id);
        if (found) {
          return found;
        }
      }
    }
    return null;
  };

  const onDelete = async () => {
    if (selectedNode && selectedNode.id !== company.id) {
      if (selectedNode.hasChildren) return;
      setLoading(true);
      const processId = selectedNode.id;
      await deleteProcess(processId);
      const removeProcessById = (processes, id) => {
        return processes.filter((process) => {
          if (process.id === id) {
            return false;
          }
          if (process.processes) {
            process.processes = removeProcessById(process.processes, id);
          }
          return true;
        });
      };

      company.processes = removeProcessById(company.processes, processId);
      setProcess(null);
      setSelectedNode(null);
      setUpdateCounter(updateCounter + 1);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setBase64File(reader.result);
        setFileExtension(file.name.split(".").pop());
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFileDelete = async (index) => {
    await deleteFile("process", process.id, index);
    const files = [...process.files];
    files.splice(index, 1);
    setProcess({ ...process, files: files });
  };

  const handleFileUpload = async (event) => {
    event.preventDefault();
    const data = {
      id: process.id,
      fileContent: base64File,
      fileExtension: fileExtension,
    };
    const r=await uploadFile("process", data);
    const files = [...process.files,r];
    setProcess({ ...process, files: files });
  };

  const onEdit = () => {
    if (selectedNode && selectedNode.id !== company.id) {
      setMode("edit");
    }
  };

  const onMove = () => {
    if (selectedNode && selectedNode.id !== company.id) {
      setMode("move");
    }
  };

  const onNodeSelected = (args) => {
    const sn = args.nodeData;
    setSelectedNode(sn);

    const processId = sn.id;
    const currentProcess = findProcessById(company.processes, processId);
    if (currentProcess) {
      setProcess(currentProcess);
    }
  };

  const onNodeAdd = async (parentId) => {
    const processId = parentId;
    setProcess({
      name: "",
      description: "",
      duration: "",
      companyId: company.id,
      parentId: processId,
      faq: [],
    });
    setMode("edit");
  };

  const onSaveProcess = async (e, newProcess) => {
    setLoading(true);
    e.preventDefault();
    if (!newProcess.id) {
      const p = await createProcess(newProcess);
      p.processes = [];
      if (newProcess.parentId) {
        const parentProcess = findProcessById(
          company.processes,
          newProcess.parentId
        );
        if (parentProcess) {
          parentProcess.processes = parentProcess.processes || [];
          parentProcess.processes.push(p);
        }
      } else {
        company.processes.push(p);
      }
    } else {
      await updateProcess(newProcess.id, newProcess);
      const updateProcessById = (processes, updatedProcess) => {
        return processes.map((process) => {
          if (process.id === updatedProcess.id) {
            return { ...process, ...updatedProcess };
          }
          if (process.processes) {
            process.processes = updateProcessById(
              process.processes,
              updatedProcess
            );
          }
          return process;
        });
      };
      setProcess(newProcess);

      company.processes = updateProcessById(company.processes, newProcess);
    }
    setProcess(null);
    setSelectedNode(null);
    setMode("view");
    setUpdateCounter(updateCounter + 1);
  };

  return (
    <div className="process-view">
      <div className="process-tree">
        <div
          style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}
        >
          <button
            onClick={() => onNodeAdd(selectedNode ? selectedNode.id : null)}
            disabled={!selectedNode}
          >
            <FontAwesomeIcon icon={faPlus} />
          </button>
          <button
            onClick={onEdit}
            disabled={!selectedNode || selectedNode.id === company.id}
          >
            <FontAwesomeIcon icon={faEdit} />
          </button>
          <button
            onClick={onDelete}
            disabled={!selectedNode || selectedNode.hasChildren}
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </button>
          <button
            onClick={onMove}
            disabled={!selectedNode || selectedNode.id === company.id}
          >
            <FontAwesomeIcon icon={faArrowsAlt} />
          </button>
        </div>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <TreeViewComponent fields={data} nodeSelected={onNodeSelected} />
        )}
      </div>
      <div className="process-details">
        {mode === "view" && selectedNode && selectedNode.id !== company.id && (
          <>
            <h3>Process Details</h3>
            <p>
              <strong>Name:</strong> {process.name}
            </p>
            <h4>Description</h4>
            <div
              dangerouslySetInnerHTML={{ __html: process?.description || "" }}
            />
            <p>
              <strong>Duration:</strong> {process.duration}
            </p>
            <h2>FAQ</h2>
            {process.faq &&
              process.faq.map((faq, index) => (
                <div key={index} className="faq-item">
                  <p>
                    <strong>{faq.question}</strong>
                  </p>
                  {faq.answer && faq.answer !== "" && <p>{faq.answer}</p>}
                </div>
              ))}
            <h2>Attachments</h2>
            {process.files &&
              process.files.map((file, index) => (
                <div key={index} className="file-item">
                  <Link
                    to="#"
                    onClick={() => window.open(file, "_blank")}
                  >{`File #${index + 1}`}</Link>
                  &nbsp;&nbsp;
                  <button onClick={() => handleFileDelete(index)}>
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </button>
                </div>
              ))}
            <div className="upload-container">
              <h3>Load file</h3>
              <form onSubmit={handleFileUpload}>
                <div className="form-group">
                  <input type="file" onChange={handleFileChange} required />
                </div>
                <div className="form-group">
                  <button type="submit" className="btn-submit">
                    Upload
                  </button>
                </div>
              </form>
            </div>
          </>
        )}
        {mode === "edit" && (
          <ProcessForm
            process={process}
            id={process.id}
            handleCancel={() => {
              setMode("view");
            }}
            handleSubmit={onSaveProcess}
          />
        )}
        {mode === "move" && (
          <ProcessMoveForm
            process={process}
            company={company}
            handleCancel={() => {
              setMode("view");
            }}
            handleSubmit={onSaveProcess}
          />
        )}
      </div>
    </div>
  );
};

export default ProcessTreeView;
